<template>
	<section>
		<div class="container-fluid">
			<div class="col-md-12">
				<div class="alt-2"></div>
				<div class="vx-row">
					<div class="vx-col w-full">
						<h4>Inversión Automática (Contratación / No Activación)</h4>
						<p class="mt-5">
							Al dar clic en Autorizar confirmarás que RedGirasol podrá invertir automáticamente tus pagos
							en el momento en que actives la inversión automática <strong>(que se encuentra desactivado
								por defecto)</strong> en el apartado de “Mi forma de riego”. Podrás habilitar y
							deshabilitar la inversión automática en el momento en el que requieras.
							<br><br>Por este medio, <strong><u>{{ userName }}</u></strong> en mi calidad de Comitente del
							Contrato de Comisión Mercantil, autorizo a Red Girasol para que pueda invertir
							automáticamente los recursos que mantengo en el Sitio en nuevos Proyectos, bajo los esquemas
							de riesgo y condiciones que he señalado en el Sitio.
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: "AutomaticInvestmentConsentV1",
	props: {
		fillUserData: {
			type: Boolean
			, default: false
		},
	},
	data() {
		return {
		};
	},
	computed: {
		userName: function () {
			if (this.fillUserData && this.user) {
				return this.user.complete_name;
			} else {
				return "";
			}
		},
	},
	methods: {
	},
};
</script>